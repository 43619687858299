// Game related but not shard specific reducers

import * as actionTypes from '~/store/actions/actionTypes'
import { updateObject } from '~/helpers'

const initialState = {
  fullScreen: false,
  power: null,
  shields: null
}

const fullScreenToggle = (state, fullScreen) => {
  return updateObject(state, {
    fullScreen
  })
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
  case actionTypes.TOGGLE_FULL_SCREEN_ON:
    return fullScreenToggle(state, true)
  case actionTypes.TOGGLE_FULL_SCREEN_OFF:
    return fullScreenToggle(state, false)
  case actionTypes.SET_POWER:
    return { ...state, power: action.payload }
  case actionTypes.SET_SHIELDS:
    return { ...state, shields: action.payload }
  default: return state
  }
}

export default reducer

/* eslint-disable key-spacing */

export const themeEliteDangerousDefault = ({
  name: 'Default',
  color: {
    primary:            '240,123,005',
    primaryHighlight:   '238,154,019',
    secondary:          '038,253,255',
    secondaryHighlight: '169,254,255',
    action:             '002,161,252',
    actionHighlight:    '012,166,255',
    warning:            '255,060,000',
    warningHighlight:   '255,080,025',
    textLight:          '169,254,255',
    textLightHighlight: '169,254,255',
    textDark:           '169,254,255',
    textDarkHighlight:  '169,254,255'
  }
})

export const themeEliteDangerousGameValues = (!process.env.NEXT_PUBLIC_GG_NODE_ENV) ? {
  name: 'Default Game',
  color: {
    primary:            '240,123,005',
    primaryHighlight:   '238,154,019',
    secondary:          '009,139,214',
    secondaryHighlight: '059,184,255',
    action:             '002,161,252',
    actionHighlight:    '012,166,255',
    warning:            '180,006,003',
    warningHighlight:   '180,030,030',
    textLight:          '169,254,255',
    textLightHighlight: '169,254,255',
    textDark:           '169,254,255',
    textDarkHighlight:  '169,254,255'
  }
} : {}

export const visualTesting = (!process.env.NEXT_PUBLIC_GG_NODE_ENV) ? {
  name: 'Visual Testing',
  color: {
    primary:            '255,255,000',
    primaryHighlight:   '225,225,000',
    secondary:          '000,055,255',
    secondaryHighlight: '000,025,225',
    action:             '000,055,255',
    actionHighlight:    '000,025,255',
    warning:            '255,000,255',
    warningHighlight:   '225,000,225',
    textLight:          '169,254,255',
    textLightHighlight: '169,254,255',
    textDark:           '169,254,255',
    textDarkHighlight:  '169,254,255'
  }
} : {}

export const BlueRed = ({
  name: 'Blue/Red',
  color: {
    primary:            '000,232,255',
    primaryHighlight:   '000,255,255',
    secondary:          '255,071,000',
    secondaryHighlight: '255,125,075',
    action:             '000,104,000',
    actionHighlight:    '000,255,000',
    warning:            '255,071,000',
    warningHighlight:   '255,125,075',
    textLight:          '169,254,255',
    textLightHighlight: '169,254,255',
    textDark:           '169,254,255',
    textDarkHighlight:  '169,254,255'
  }
})

export const Green = ({
  name: 'Green',
  color: {
    primary:            '000,182,091',
    primaryHighlight:   '196,238,171',
    secondary:          '253,200,178',
    secondaryHighlight: '255,125,075',
    action:             '196,238,171',
    actionHighlight:    '249,238,215',
    warning:            '253,200,178',
    warningHighlight:   '255,125,075',
    textLight:          '169,254,255',
    textLightHighlight: '169,254,255',
    textDark:           '169,254,255',
    textDarkHighlight:  '169,254,255'
  }
})

export const Red = ({
  name: 'Red',
  color: {
    primary:            '232,046,001',
    primaryHighlight:   '252,100,062',
    secondary:          '000,213,242',
    secondaryHighlight: '091,235,235',
    action:             '000,213,242',
    actionHighlight:    '091,235,235',
    warning:            '253,200,178',
    warningHighlight:   '255,125,075',
    textLight:          '169,254,255',
    textLightHighlight: '169,254,255',
    textDark:           '169,254,255',
    textDarkHighlight:  '169,254,255'
  }
})

// const themeEliteDangerousDefaultVisual = {
//   name: 'Default',
//   color: {
//     primary:            'rgb(255,111,000)',
//     primaryHighlight:   'rgb(238,154,019)',
//     secondary:          'rgb(038,253,255)',
//     secondaryHighlight: 'rgb(169,254,255)',
//     action:             'rgb(169,254,255)',
//     actionHighlight:    'rgb(169,254,255)',
//     warning:            'rgb(255,000,000)',
//     warningHighlight:   'rgb(169,254,255)',
//     textLight:          'rgb(169,254,255)',
//     textLightHighlight: 'rgb(169,254,255)',
//     textDark:           'rgb(169,254,255)',
//     textDarkHighlight:  'rgb(169,254,255)'
//   }
// }

import { api } from '~/utils/axiosInstances'
import {
  get,
  setOmniStorageItems,
  orderObjectKeys,
  storage,
  wait, omniStorage
} from '~/helpers'
import {
  setDimension
} from '~/helpers/google-analytics'
import * as actionTypes from './actionTypes'
import deepEqual from 'fast-deep-equal'
import {webrtc} from "~/providers/MfdProvider";

export const authenticationStart = () => {
  return {
    type: actionTypes.AUTHENTICATION_START
  }
}

export const authenticationSuccess = payload => {
  payload.user = orderObjectKeys(payload.user)

  return {
    type: actionTypes.AUTHENTICATION_SUCCESS,
    payload
  }
}

export const authenticationFail = (error = null) => {
  return {
    type: actionTypes.AUTHENTICATION_FAIL,
    payload: { error }
  }
}

export const authenticationLoggedOut = () => {
  return {
    type: actionTypes.AUTHENTICATION_LOGGED_OUT,
    payload: null
  }
}

export const authenticationLoggedOutFinished = () => {
  return {
    type: actionTypes.AUTHENTICATION_LOGGED_OUT_FINISHED,
    payload: null
  }
}

export const logout = () => {
  return async (dispatch) => {

        omniStorage.removeAll()
        storage.removeItem('token')

        if (window?.Android) {
          window.Android.clearToken()
        }

        if (window?.Amazon) {
          window.Amazon.clearToken()
        }

    webrtc.stop()
    localStorage.clear()
    sessionStorage.clear()
    dispatch(authenticationLoggedOut())
    dispatch(authenticationLoggedOutFinished())
  }
}

const apiGetMe = async (retries = 3) => {
  let response
  try {
    response = await api.get('users/@me')
    return response
  } catch (error) {
    if (--retries > 0) {
      console.warn(`/users/@me retries left: ${retries}`)
      return wait(1000).then(() => apiGetMe(retries))
    }

    throw error
  }
}

export const getUser = () => {
  return async (dispatch, getStore) => {
    dispatch(authenticationStart())
    let user
    const token = storage.getItem('token')

    if (token) {
      try {
        let hosts = []
        const { data: userData } = await apiGetMe()

        if (userData.host) {
          hosts = [userData.host]
        }

        user = {
          ...userData,
          hosts
        }
        setOmniStorageItems(user, hosts)
      } catch (e) {
        console.warn([e])

        let error = null

        if (
          get(e, 'response.status') === 401 &&
          get(e, 'response.data.detail')
        ) {
          error = get(e, 'response.data')

          if (error) {
            error.non_field_errors = e.non_field_errors || []
            error.non_field_errors.push(e.response.data.detail)
          }
          sessionStorage.setItem('tempError', e.response.data.detail)
        }

        dispatch(authenticationFail(error))
        dispatch(logout())
      }
    }

    if (user) {
      try {
        const currentUser = getStore().authenticationReducer.user

        if (!deepEqual(user, currentUser)) {
          dispatch(authenticationSuccess({ user, token }))
        }
      } catch (e) {
        dispatch(authenticationSuccess({ user, token }))
      }

      setDimension({ dimension2: user.id, dimension1: user.features?.includes('glasspass') })

    } else {
      dispatch(authenticationFail())
      dispatch(logout())
    }

    return user
  }
}

export const setAuthenticationRedirectPath = path => {
  return {
    type: actionTypes.SET_AUTHENTICATION_REDIRECT_PATH,
    payload: { path }
  }
}

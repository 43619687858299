// Game related but not shard specific actions
import * as actionTypes from '../../actionTypes'

export const TOGGLE_FULL_SCREEN_ON = () => {
  return {
    type: actionTypes.TOGGLE_FULL_SCREEN_ON
  }
}

export const TOGGLE_FULL_SCREEN_OFF = () => {
  return {
    type: actionTypes.TOGGLE_FULL_SCREEN_OFF
  }
}

export const setPower = (payload) => {
  return {
    type: actionTypes.SET_POWER,
    payload
  }
}

export const setShields = (payload) => {
  return {
    type: actionTypes.SET_SHIELDS,
    payload
  }
}

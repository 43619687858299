import React, {useEffect, useRef, useState} from 'react'
import {useRouter} from 'next/router'
import {MfdContext} from '~/contexts'
import {deepClone, get, isIOS, isMobile, omniStorage, setNestedProp, storage,} from '~/helpers'
import {TEST_EMAILS} from '~/config/settings'
import {themeStarCitizenDefault} from '~/components/apps/starCitizen/themes/starcitizen-presets.js'
import {themeEliteDangerousDefault} from '~/components/apps/eliteDangerous/themes/elite-dangerous-presets'
import * as Schema from 'yup'
import deepEqual from 'fast-deep-equal'
import Loading from '~/components/Loading'
import {ClientWebRTC, CustomEvents, LogTypes, PeerEvents} from '@gameglass/webrtc'
import useAnnouncements from '~/hooks/useAnnouncements'
import {HOST_CONNECTION_ERROR} from '~/utils/announcements'
import {isProduction} from '~/helpers/environment'
import {ACTION_TYPES} from '@gameglass/common'
import {useSelector} from "react-redux";
import {penpalChildConnection} from "~/components/ShardIframe";

const CONNECTED_TIMEOUT = 30000

const ConnectedStates = {
  CONNECTED: true,
  DISCONNECTED: false,
  CONNECTING: null
}

const mfdOmniStorage = omniStorage.prefixed('MFD_')

const soundDirectory = 'sound/'

const keyboardLayouts = {
  qwertz: {
    y: 'z',
    z: 'y'
  },
  azerty: {
    q: 'a',
    w: 'z',
    a: 'q',
    z: 'w'
  }
}

const convertKey = (keyboardLayout, key) => {
  return get(keyboardLayouts, `${keyboardLayout}.${key}`) || key
}

const defaultSettings = {
  volume: 100,
  altSounds: {},
  keyboardLayout: '',
  starcitizen: {
    theme: themeStarCitizenDefault
  },
  elitedangerous: {
    theme: themeEliteDangerousDefault
  }
}

const getDefaultsAndStorageSettings = () => {
  return {
    ...defaultSettings,
    ...mfdOmniStorage.get('SETTINGS')
  }
}

const noUnknownArgs = [
  true
]

const themeSchema = Schema
  .object()
  .noUnknown(...noUnknownArgs)
  .shape({
    name: Schema.string().required(),
    color: Schema
      .object()
      .noUnknown(...noUnknownArgs)
      .shape({
        primary: Schema.string().required(),
        primaryHighlight: Schema.string().required(),
        secondary: Schema.string().required(),
        secondaryHighlight: Schema.string().required(),
        action: Schema.string().required(),
        actionHighlight: Schema.string().required(),
        warning: Schema.string().required(),
        warningHighlight: Schema.string().required()
      })
  })

const gameSchema = Schema
  .object()
  .noUnknown(...noUnknownArgs)
  .shape({
    theme: themeSchema,
    themes: Schema.array().of(themeSchema),
    background: Schema.string()
  })

const settingsSchema = Schema
  .object()
  .noUnknown(...noUnknownArgs)
  .shape({
    altSounds: Schema
      .object()
      .default(defaultSettings.altSounds),
    volume: Schema
      .number()
      .default(defaultSettings.volume),
    selectedGame: Schema
      .string()
      .default(defaultSettings.selectedGame),
    starcitizen: gameSchema
      .default(defaultSettings.starcitizen),
    elitedangerous: gameSchema
      .default(defaultSettings.elitedangerous)
  })

const validateThemes = (settings, user) => {
  const games = ['starcitizen', 'elitedangerous']

  try {
    if (!get(user, 'features', []).includes('visual_themes')) {
      games
        .map(game => {
          settings[game].theme = deepClone(defaultSettings[game].theme)
        })
    }
  } catch {
    // noop
  }
}

const validateSettings = (settings, user, retries = 0) => {
  if (retries >= 50) {
    console.error('Possible validation loop. Using previous or default settings.')

    return getDefaultsAndStorageSettings()
  }

  try {
    validateThemes(settings, user)

    return settingsSchema.validateSync(settings)
  } catch (error) {
    console.error('=========VALIDATION ERROR=========')
    console.error(error)

    setNestedProp(
      settings,
      error.path,
      get(defaultSettings, error.path)
    )

    return validateSettings(settings, user, ++retries)
  }
}

class Queue {
  _processing = false
  _queue = []

  _process = async () => {
    this._processing = true

    const callback = this._queue.shift()

    if (typeof callback === 'function') {
      await callback()
    }

    if (this._queue.length > 0) {
      this._process()
    } else {
      this._processing = false
    }
  }

  push = (callback) => {
    this._queue.push(callback)

    if (!this._processing) {
      this._process()
    }
  }
}

export let webrtc = null

const allowedFields = ['type', 'payload']

const MFD = (() => {
  let mfdInstance = null

  // const acceptedModifiers = Object.freeze([
  //   'shift',
  //   'ctrl',
  //   'alt',
  //   'ralt',
  //   'windows',
  //   'command'
  // ])

  let peerId = null

  const setPeerID = () => {
    if (peerId) {
      peerId = webrtc.peerId || Math.random()
    }

    return () => {
      const fingerprint = localStorage.getItem('fingerprint')
      webrtc.peerId = `${peerId}-${fingerprint}` || webrtc.peerId
    }
  }

  // const isAlphaOrBeta = typeof location !== 'undefined' ? location.host.match('^(alpha|beta)') : false

  if (typeof window !== 'undefined' || typeof window !== 'undefined') {
    webrtc = new ClientWebRTC({
      debug: !isProduction,
      httpUrl: process.env.NEXT_PUBLIC_WEBRTC_HTTP_URL,
      webSocketUrl: process.env.NEXT_PUBLIC_WEBRTC_WEBSOCKET_URL,
      tokenUrl: process.env.NEXT_PUBLIC_WEBRTC_TOKEN_URL
    })

    setPeerID()
  }

  return class MFD {
    constructor(user) {
      if (!mfdInstance) {
        mfdInstance = this
      }

      mfdInstance.user = user

      if (!mfdInstance.webrtc) {
        mfdInstance.webrtc = this.webrtc = webrtc

        let timeout

        const onConnect = () => {
          clearTimeout(timeout)
          this.setConnected(ConnectedStates.CONNECTED)
          this.update()
        }

        const onError = () => {
          clearTimeout(timeout)
          this.setConnected(ConnectedStates.DISCONNECTED)
        }

        const onData = async ({ data }) => {
          this.setConnected(ConnectedStates.CONNECTED)

          const { type, payload } = data

          if ([
            ACTION_TYPES.getPrefs,
            ACTION_TYPES.setPrefs
          ].includes(type)) {
            if (type === ACTION_TYPES.getPrefs) {
              this.hasEverReceivedPrefs = true

              if (!deepEqual(payload.prefs, this.settings)) {
                this.settings = payload.prefs
              }
            }

            this.resolvers.prefs.forEach(resolve => resolve(this.settings))
            this.resolvers.prefs.length = 0
          }

          if (ACTION_TYPES.forgeUpdateSignal === type) {
            if (location.pathname.split('/').pop() === `${payload.id}`) {
              const child = await penpalChildConnection.promise
              child.reload()
            }
          }

        }

        const onPeersChange = () => {
          clearTimeout(timeout)

          this.setConnected(this.connected || null)

          timeout = setTimeout(() => {
            let connected = false

            if (webrtc.peer) {
              connected = webrtc.peer.connected || null
            }

            this.setConnected(connected)
          }, CONNECTED_TIMEOUT)
        }

        webrtc.on(PeerEvents.data, onData)
        webrtc.on(CustomEvents.peersChange, onPeersChange)
        webrtc.on(PeerEvents.error, onError)
        webrtc.on(PeerEvents.connect, onConnect)
        // Using the debugger feature because we're missing this specific event type
        webrtc.on(CustomEvents.debugger, ({ data }) => {
          if (data?.title === LogTypes.signalReceived) {
            const isCandidate = data?.args?.[0]?.event === 'CANDIDATE'

            isCandidate && this.setConnected(ConnectedStates.CONNECTING)
          }
        })
      }

      if (user) {
        if (!webrtc.token) {
          webrtc.token = storage.getItem('token')
        }
        !webrtc.peer && webrtc.start()
      } else {
        webrtc.stop()
      }

      setPeerID()

      if (!mfdInstance.settings) {
        mfdInstance.settings = getDefaultsAndStorageSettings()
      }

      MFD.current.mfd = mfdInstance

      return mfdInstance
    }

    static current = {
      mfd: null,
      settings: null,
      connected: null
    }

    queue = new Queue()

    _settings = null

    get settings() {
      return this._settings
    }

    set settings(settings) {
      const validatedSettings = validateSettings({
        ...getDefaultsAndStorageSettings(),
        ...this.settings,
        ...settings
      }, this.user)

      this._settings = validatedSettings
      MFD.current.settings = validatedSettings
      mfdOmniStorage.set('SETTINGS', validatedSettings)
      this.settingsChangedCallback && this.settingsChangedCallback(this.settings)
    }

    get preferences() {
      return this.settings
    }

    set preferences(settings) {
      this.settings = settings
    }

    connected = null

    setConnected = (connected) => {
      const { user } = this

      if (!1 && user && (TEST_EMAILS.includes(user.email) || user.is_staff)) {
        connected = ConnectedStates.CONNECTED
      }
      // else if (!this.hasSignalled) {
      //   connected = null
      // }

      this.connected = connected
      MFD.current.connected = connected
      this.connectedCallback && this.connectedCallback(this.connected)
    }

    action = (_unused, active, mfdArray, value) => {
      if (active && mfdArray && mfdArray.length > 0) {
        const keyboardLayout = this.settings.keyboardLayout
        // For loop for speed and chaining key presses
        for (let i = 0; i < mfdArray.length; i++) {
          const key = convertKey(keyboardLayout, mfdArray[i].key)

          console.warn(
            `Key: ${mfdArray[i].key || mfdArray[i].button}(${key}), modifiers: [${(mfdArray[i].modifierArray || []).join(', ')}], type: ${mfdArray[i].type}`
          )
          switch (mfdArray[i].type) {
          case 'mousePress':
            this.mouseClick(mfdArray[i].button).playMP3(mfdArray[i].sound)
            break
          case 'mouseOn':
          case 'mouseButtonDown':
            this.mouseOn(mfdArray[i].button).playMP3(mfdArray[i].sound)
            break
          case 'mouseOff':
          case 'mouseButtonUp':
            this.mouseOff(mfdArray[i].button).playMP3(mfdArray[i].sound)
            break
          case 'mouseWheel':
            this.mouseWheel(value || mfdArray[i].clicks).playMP3(
              mfdArray[i].sound
            )
            break
          case 'tap':
            this.keyTap(key, mfdArray[i].modifierArray, mfdArray[i].sound)
            break
          case 'dtap':
            this.keyTap(key, mfdArray[i].modifierArray, mfdArray[i].sound).keyTap(
              key,
              mfdArray[i].modifierArray
            )
            break
          case 'on':
            this
              .keyOn(key, mfdArray[i].modifierArray)
              .playMP3(mfdArray[i].sound)
            break
          case 'onWait':
            this
              .keyOn(key, mfdArray[i].modifierArray)
              .playMP3(mfdArray[i].sound)
              .wait(mfdArray[i].wait.time)
              .keyOff(mfdArray[i].wait.keyOff, mfdArray[i].modifierArray)
            break
          case 'off':
            this.keyOff(key, mfdArray[i].modifierArray).playMP3(mfdArray[i].sound)
            break
          case 'console': {
            const string = key
              .split('')
              .map(char => {
                return convertKey(keyboardLayout, char)
              })
              .join('')

            if (keyboardLayout === 'qwertz') {
              this.keyOn('shift')
                .keyTap('backquote')
                .keyOff('shift')
                .typeString(string)
                .keyTap('enter')
                .keyOn('shift')
                .keyTap('backquote')
                .keyOff('shift')
            } else {
              this.keyTap('backquote')
                .typeString(string)
                .keyTap('enter')
                .keyTap('backquote')
            }
            break
          }
          case 'playMP3':
            this.playMP3(mfdArray[i].sound)
            break
          case 'hostKeybinds':
            console.log('Command: ', mfdArray[i].game, mfdArray[i].section, mfdArray[i].command)
            this.hostKeybinds(mfdArray[i].game, mfdArray[i].section, mfdArray[i].command)
            break
          default:
            break
          }
        }
      } else {
        console.warn('Button action is not active')
      }
    }

    queueAction = (action) => {
      if (typeof action === 'object') {
        if (!('type' in action)) {
          return console.error('An action must contain a "type" field. Received: ', action)
        }

        if (!ACTION_TYPES[action.type]) {
          return console.error(`An action must a valid type field. Received: ${action.type}`, 'Valid types: ', Object.keys(ACTION_TYPES))
        }

        if (!Object.keys(action).every(key => allowedFields.includes(key))) {
          return console.error(`An action can only contain these fields: ${allowedFields.join(', ')}. Received: `, action)
        }

        this.queue.push(() => {
          this.webrtc.send(action)
        })
      } else {
        return console.error('An action must be an object.')
      }
    }

    gameSpecificAction = ({ game, action }) => {
      if (game && action) {
        this.queueAction({
          type: ACTION_TYPES.gameSpecificAction,
          payload: {
            game,
            action
          }
        })
      }
    }

    resolvers = {
      prefs: []
    }

    prefs = (prefs = false) => {
      return new Promise(resolve => {
        if (prefs && this.hasEverReceivedPrefs) {
          const settings = validateSettings({
            ...getDefaultsAndStorageSettings(),
            ...this.settings,
            ...prefs
          }, this.user)

          this.webrtc.sendWhenConnected({
            type: ACTION_TYPES.setPrefs,
            payload: {
              prefs: settings
            }
          })
        } else {
          this.webrtc.sendWhenConnected({
            type: ACTION_TYPES.getPrefs
          })
        }

        const timeout = setTimeout(() => {
          const settings = {
            ...getDefaultsAndStorageSettings(),
            ...this.settings
          }

          resolve(settings)

          this.setConnected(ConnectedStates.DISCONNECTED)
        }, CONNECTED_TIMEOUT)

        this.resolvers.prefs.push((prefs) => {
          clearTimeout(timeout)
          resolve(prefs)
          this.setConnected(ConnectedStates.CONNECTED)
        })
      })
    }

    hostKeybinds = (game, section, command) => {
      const payload = {
        program: game,
        shard: section,
        command: command
      }
      this.queueAction({ type: 'run', payload })
      return this
    }

    buildKeyHandler = (type) => {
      return (key, modifiers, sound) => {
        if (typeof key !== 'string') {
          console.error(`Bad input, use mfd.${type}(key)`)
        } else {
          const payload = { key, modifiers, sound }

          this.queueAction({ type, payload })
        }

        return this
      }
    }

    keyUp = this.keyOff = this.buildKeyHandler(ACTION_TYPES.keyUp)

    keyDown = this.keyOn = this.buildKeyHandler(ACTION_TYPES.keyDown)

    keyTap = this.keyPress = this.buildKeyHandler(ACTION_TYPES.keyTap)

    typeString = (string) => {
      if (typeof string !== 'string') {
        console.error('Bad input, use mfd.typeString(string)')
      } else {
      /* eslint-disable */
      const payload = {
        string: string.replace(/\"/g, '\\"').replace(/\\/g, '\\\\')
      }
      /* eslint-enable */
        this.queueAction({ type: ACTION_TYPES.typeString, payload })
      }
      return this
    }

    screenshots = {
      get: () => new Promise(resolve => resolve({ files:[] })),
      snap: (payload) => new Promise(resolve => {
        this.queueAction({ type: ACTION_TYPES.screenshot, payload })
        resolve(null)
      }),
      remove: () => new Promise(resolve => resolve(null))
    }

    open = this.openDir = (path) => {
      if (typeof path !== 'string') {
        console.error(`Bad input, use mfd.open(path)`)
      } else {
        const payload = { path }

        this.queueAction({ type: ACTION_TYPES.open, payload })
      }

      return this
    }

    url = (url) => {
      if (typeof url !== 'string') {
        console.error(`Bad input, use mfd.url(url)`)
      } else {
        const payload = { url }

        this.queueAction({ type: ACTION_TYPES.url, payload })
      }

      return this
    }

    play = this.playMP3 = (path, volume, useAltSound = true) => {
      if (path) {
        if (typeof path === 'string') {
          const payload = {
            sound: soundDirectory + path,
            volume,
            useAltSound
          }

          this.queueAction({ type: ACTION_TYPES.play, payload })
        } else {
          console.warn('Bad input, use mfd.play(path)')
        }
      }

      return this
    }

    setClientLocation = (url) => {
      const clientLocation = url || window.location.pathname

      // this.queueAction({
      //   type: ACTION_TYPES.setClientLocation,
      //   payload: {
      //     clientLocation
      //   },
      //   sendWhenConnected: true
      // })

      this.webrtc.sendWhenConnected({
        type: ACTION_TYPES.setClientLocation,
        payload: {
          clientLocation
        }
      })
    }

    sync = ({
      update = {}
    } = {}) => this.update(update)

    update = this.mfdUpdate = async (update = {}) => {
      const settings = {
        ...await this.prefs(),
        ...update
      }

      this.settings = await this.prefs(settings)

      return this
    }

  // TODO: Mouse actions: mouseClick: ƒ (button), mouseMove: ƒ (x, y), mouseOff: ƒ (button), mouseOn: ƒ (button), mouseWheel: ƒ (clicks)
  }
})()

const connectToHostLoadingMessage = (seconds = 0) => `Connecting to host... (${seconds}s)`

function MFDProvider (props) {
  const [mfd, setMfd] = useState(MFD.current.mfd)
  const [connected, setConnected] = useState(MFD.current.connected)
  const [isReloading, setReloading] = useState(false)
  const [settings, setSettings] = useState(MFD.current.settings)
  const [visibilityTime, setVisibilityTime] = useState(0)
  const { addAnnouncement, removeAnnouncement } = useAnnouncements()
  const user = useSelector((state) => {
    return state?.authenticationReducer?.user
  })

  const router = useRouter()

  const mfdInit = () => {
    let newMFD = null

    if (user) {
      newMFD = new MFD(user)
    }

    if (newMFD) {
      newMFD.connectedCallback = (connected) => {
        setConnected(connected)
      }

      newMFD.settingsChangedCallback = (settings) => {
        setSettings({ ...settings })
      }

      newMFD.update().then(updatedMFD => {
        setMfd(updatedMFD)
        setConnected(updatedMFD.connected)
        setSettings({ ...updatedMFD.settings })
      })
    } else {
      setMfd(null)
      setConnected(ConnectedStates.CONNECTING)
      setSettings(null)
    }
  }

  const reload = () => {
    removeAnnouncement(HOST_CONNECTION_ERROR)
    setLoadingMessage("Reconnecting...")
    setReloading(true)
    window.location.reload()
  }

  useEffect(() => {
    let prevConnected = webrtc?.peer?.connected
    let prevHiddenTime = Date.now()
    const reloadTime = isIOS ? 30 * 1000 : 10 * 60 * 1000
    const onVisibilityChange = () => {
      const diffTime = Date.now() - prevHiddenTime
      if(window.document.hidden) {
        prevHiddenTime = Date.now()
        prevConnected = webrtc?.peer?.connected
      } else if((diffTime >= reloadTime || !webrtc?.peer?.connected) && prevConnected) {
        reload()
      } else if (prevConnected) {
        setVisibilityTime(Date.now())
      }
    }

    typeof window !== 'undefined' && isMobile && window.document.addEventListener("visibilitychange", onVisibilityChange);

    return () => {
      typeof window !== 'undefined' && isMobile && window.document.removeEventListener("visibilitychange", onVisibilityChange);
    }
  }, []);

  useEffect(() => {
    mfdInit()
    return () => {
      setMfd(null)
      setConnected(ConnectedStates.CONNECTING)
      setSettings(null)
    }
  }, [user])

  useEffect(() => {
    const diff = Date.now() - visibilityTime
    // If it tries to reconnect within 5 seconds after coming back to app, reload to reconnect
    if(connected !== ConnectedStates.CONNECTED && diff <= 5000) {
      reload()
    } else if (connected === ConnectedStates.DISCONNECTED) {
      addAnnouncement(HOST_CONNECTION_ERROR)
    } else {
      removeAnnouncement(HOST_CONNECTION_ERROR)
    }
  }, [connected, addAnnouncement])

  useEffect(() => {
    const setClientLocation = (url) => {
      mfd?.setClientLocation?.(url)
    }

    setClientLocation()

    router.events.on('routeChangeComplete', setClientLocation)

    return () => {
      router.events.off('routeChangeComplete', setClientLocation)
    }
  }, [router, mfd])

  const intervalRef = useRef()
  const [loadingMessage, setLoadingMessage] = useState('')

  useEffect(() => {
    if (connected !== ConnectedStates.CONNECTING) {
      clearTimeout(intervalRef.current)
      intervalRef.current = undefined
      setLoadingMessage('')
    } else if (!intervalRef.current) {
      const start = Date.now()
      setLoadingMessage(connectToHostLoadingMessage())
      intervalRef.current = setInterval(() => {
        const end = Date.now()
        setLoadingMessage(connectToHostLoadingMessage(Math.round((end - start) / 1000)))
      }, 1000)
    }

    return () => {
      clearTimeout(intervalRef.current)
      intervalRef.current = undefined
    }
  }, [connected])

  return (
    <MfdContext.Provider value={{
      mfd,
      connected,
      settings,
      mfdConnected: connected,
      mfdSettings: settings
    }}>
      {(mfd && settings && (connected === ConnectedStates.CONNECTED) && !isReloading) ? props.children : <Loading fixed message={loadingMessage} />}
    </MfdContext.Provider>
  )
}

export default MFDProvider

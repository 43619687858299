/* eslint-disable key-spacing */
export const themeStarCitizenDefault = {
  name: 'Default',
  color: {
    primary:            '043,237,230',
    primaryHighlight:   '170,255,255',
    secondary:          '255,000,000', // dark
    secondaryHighlight: '023,089,108',
    action:             '079,227,139',
    actionHighlight:    '177,253,204',
    warning:            '237,055,055',
    warningHighlight:   '250,108,108'
  }
}

// FOR REFERENCE
export const themeStarCitizenDefaultReference = !process.env.NEXT_PUBLIC_GG_NODE_ENV ? {
  name: 'Star Citizen Reference',
  color: {
    primary:            '043,237,230',
    primaryHighlight:   '043,237,230',
    secondary:          '255,000,000',
    secondaryHighlight: '023,089,108',
    action:             '079,227,139',
    actionHighlight:    '177,253,204',
    warning:            '237,055,055',
    warningHighlight:   '250,108,108'
  }
} : {}

// FOR REFERENCE
export const themeStarCitizenLightGreen = {
  name: 'Light Green',
  color: {
    primary:            '013,160,072',
    primaryHighlight:   '150,255,189',
    secondary:          '003,064,087',
    secondaryHighlight: '023,089,108',
    action:             '079,227,139',
    actionHighlight:    '177,253,204',
    warning:            '237,055,055',
    warningHighlight:   '255,078,078'
  }
}

// FOR REFERENCE
export const themeStarCitizenBlue = {
  name: 'Blue',
  color: {
    primary:            '061,141,252',
    primaryHighlight:   '108,191,255',
    secondary:          '255,255,255',
    secondaryHighlight: '061,141,252',
    action:             '079,227,139',
    actionHighlight:    '177,253,204',
    warning:            '237,055,055',
    warningHighlight:   '255,078,078'
  }
}

// FOR REFERENCE
export const themeStarCitizenRed = {
  name: 'Red',
  color: {
    primary:            '254,019,026',
    primaryHighlight:   '255,081,082',
    secondary:          '003,064,087',
    secondaryHighlight: '023,089,108',
    action:             '079,227,139',
    actionHighlight:    '177,253,204',
    warning:            '255,106,016',
    warningHighlight:   '255,133,059'
  }
}

export const themeStarCitizenWindu = {
  name: 'Windu',
  color: {
    primary:            '238, 0, 255',
    primaryHighlight:   '0, 156, 255',
    secondary:          '0, 255, 29',
    secondaryHighlight: '255, 0, 25',
    action:             '079,227,139',
    actionHighlight:    '177,253,204',
    warning:            '255, 187, 0',
    warningHighlight:   '255, 187, 0'
  }
}
